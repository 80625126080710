<template>
  <div class="ui image upload">
    <div class="my-8">
      <image-uploader
        :preview="true"
        :className="['fileinput', { 'fileinput--loaded': hasImage }]"
        capture="environment"
        :debug="1"
        doNotResize="gif"
        :autoRotate="false"
        outputFormat="file"
        accept="image/*"
        :maxWidth="100"
        :maxHeight="100"
        @input="setImage"
      >
        <label for="fileInput" slot="upload-label">
            <div v-if="hasImage===false" style="display:inline-flex;margin-left:-10px;">
            <svg style="fill:#61808C;cursor:pointer;"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                class="path1"
                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
              ></path>
            </svg>
            </div>
          <div class="upload-caption">
              <div v-if="hasImage===false">Upload an image</div>
              <div v-else>Replace</div>
          </div>
        </label>
      </image-uploader>
    </div>
  </div>
</template>

<script>
import ImageUploader from 'vue-image-upload-resize'
import store from '@/services/store'
export default {
  name: 'imageUpload',
  data () {
    return {
      hasImage: false,
      image: null
    }
  },
  components: {
    'image-uploader': ImageUploader
  },
  methods: {
    setImage: function (output) {
      this.hasImage = true
      this.image = output
      // console.log('Info', output.info)
      // console.log('Exif', output.exif)
      console.log('image', this.image)
      store.commit('app/setLocalChannelImage', this.image)
    },
    getCommand () {
      if (hasImage) {
        return 'Replace'
      } else {
        return 'Upload an image'
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#fileInput {
  display: none;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.my-8 .img-preview {
  height: 100px;
  width: 100px;
  padding: 2px;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  border: black;
  border-width: 2px;
}
.my-8 .upload-caption {
  margin-top: 2px;
  font-size:14px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
  color:#7596A4;
}
</style>
