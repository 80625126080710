<template>
<!-- invite users to be a collaborator -->
<div class="ui publish modal" style="text-align:center;background:#324851;">
    <div v-if='currentState===2' class="red ui inverted basic segment">There is some issues with publishing your doc. <a style="cursor:pointer;color:white;text-decoration:underline;" @click="openIntercom">Talk to us</a></div>
     <div v-if='currentState===201' class="green ui inverted basic segment">Success 🎉 Channel created.  <a style="cursor:pointer;color:white;text-decoration:underline;" @click="channelLink">See Here</a></div>
    <div v-html="states[currentState]" v-else-if='currentState in states' :class="[currentState<500 && currentState>200?'green':'red','ui inverted basic segment']"></div>
    <div v-if="hasChannel===true">
        <h3 class="ui center aligned header" style="color:white;margin-top:20px;margin-bottom:20px">Publish</h3>
        <div style="font-size:15px;color:white;">Publish
            <div class="ui transparent input borderBottom">
                <input type="text" placeholder="Name Your Document" v-model="title" style="width:250px;color: #ffffff;text-align:center;">
                <i class="fa fa-pencil" style="font-size:10px;padding-left:2em;color:white;padding-right:1em;"></i>
            </div>
            <span style="font-size:15px;color:white;">on the</span>
        </div>
        <diV>
            <div class="ui transparent input borderBottom" style="margin-top:30px;cursor:pointer" @click="channelLink"> {{channelName}} </div>
            <span style="font-size:15px;color:white;">&nbsp;&nbsp;channel</span>
        </div>
        <div v-if='currentState==202' style="font-size:14px;color:white;margin-top:20px">Great 👍 You'll receive an email once your published doc is ready!</div>
        <div style="margin-top:30px">
            <button v-if='currentState===0 || currentState===201' class="btn-publish" @click="publish">Publish</button>
            <button v-if='currentState===0 || currentState===201' class="ui inverted basic button" @click="sampleLink">See a Sample</button>
        </div>
        <div style="margin-top:30px; color:#5A29A3;margin-bottom:-20px">By publishing, you acknowledge that you are not<br>violating others’ copyright or privacy rights. <a href="https://www.spext.co/privacy" target="_blank"> Learn more</a></div>
        <!-- for future -->
        <div class="actions" style="text-align:center;margin-top:60px;background:#465962;"></div>

    </div>
    <!-- if channel is not there, create a new channel for user -->
    <div v-else>
        <h3 class="ui center aligned header" style="color:white;margin-top:20px">Publish</h3>
        <!-- <h3 class="ui center aligned header" style="color:white;margin-top:20px">
            Pick an Image and Name and Image
        </h3> -->
        <div style="margin-top:20px;font-size:34px;">🌎</div>
        <h3 class="ui center aligned header" style="color:white;margin-top:10px">Publish to your audience.</h3>
        <div style="margin-top:-10px; color:#7596A4;">Your audience can use the same autogenerated<br>transcript to “Search, Snip & Discuss” your work.</div>
        <button class="ui inverted basic button" style="margin-top:10px" @click="sampleEpisode">See a Sample</button>
        <h3 class="ui center aligned header" style="color:white;margin-top:30px">First, lets create your channel.</h3>
        <imageUpload />
        <!-- <div style="margin-top:10px;"></div> -->
        <div class="ui transparent input borderBottom" style="margin-left:20px;margin-right:20px;color:white">
            <input type="text" placeholder="Name Your Channel" v-model="publishChannel" style="width:250px;text-align:center;color:white;">
        </div>
        <!-- add description here -->
        <!-- <div class="ui transparent input">
            <textarea class="ui transparent input" placeholder="Description" v-model="channelDesc" style="width:250px;color:white;background-color:transparent"></textarea>
        </div> -->
        <div v-if="!uploadStatus"><button class="btn-publish" style="margin-top:20px;margin-bottom:-20px" @click="createChannel">Create</button></div>
        <div v-else-if="uploadStatus==='inprogress'" class="upload-progress-container">
            <div class="upload-info">
              <span style="color:#7596A4;">Creating..</span>
                <span style="color:#7596A4;font-size:14px">({{ percentage }}%)</span>
            </div>
            <div class="progress-bar">
                <div class="progress-outer">
                    <div class="progress-inner" id="progress-inner" :style="'width:'+percentage+'%;'"></div>
                </div>
            </div>
        </div>
        <div v-else-if="uploadStatus==='complete'" class="upload-complete-container">
            <span class="icon-check-circle"><i class="fas fa-check-circle"></i></span>
        </div>

        <div class="actions" style="text-align:center;margin-top:60px;background:#465962;"></div>
    </div>
</div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {
  humanLength,
  copyToClipboard
} from '@/utilities/utility'
import store from '@/services/store'
import {
  uploadImagePublish,
  getURLfromPath
} from '../AddFiles/utility'
import axios from 'axios'
import CONSTANTS from '../../../../../constants/CONSTANTS'
import imageUpload from '../mini-components/imageUpload'
const uuidv1 = require('uuid/v1')

export default {
  data () {
    return {
      email: '',
      states: {
        // 201: 'Success 🎉 Channel created!  <span style="cursor:pointer;color:white;text-decoration:underline" @click="channelLink">See Here<span>',
        202: 'Success 🎉 Publishing process started',
        501: 'Not able to create your channel.  <a style="cursor:pointer;color:white;text-decoration:underline" onclick="window.Intercom(`showNewMessage`)">Contact Support<a>',
        502: 'Please upload a photo',
        503: 'Please enter a filename',
        504: 'Note able to publish your doc. <a style="cursor:pointer;color:white;text-decoration:underline;"onclick="window.Intercom(`showNewMessage`)">Talk to us</a>',
        505: `Please enter a channel name`,
        507: 'Network Error'
      },
      currentState: 0,
      active: {},
      countdownId: '',
      podId: '',
      ownerId: '',
      publishTitle: '',
      publishChannel: '',
      publishId: '',
      channelDesc: '',
      freshChannel: '',
      percentage: 0,
      uploadStatus: ''
    }
  },
  components: {
    imageUpload
  },
  computed: {
    title: {
      get: function () {
        if (this.publishTitle) {
          return this.publishTitle
        }
        if (store.state.editor.podcastInfo && store.state.editor.podcastInfo.title) {
          return store.state.editor.podcastInfo.title
        }
        return ''
      },
      set: function (newVal) {
        this.publishTitle = newVal
      }
    },
    channelName: {
      get: function () {
        if (this.publishChannel) {
          return this.publishChannel
        }
        if (store.state.app.userInfo && store.state.app.userInfo.channel && store.state.app.userInfo.channel.name) {
          return store.state.app.userInfo.channel.name
        } else {
          return ''
        }
      },
      set: function (newVal) {
        this.publishChannel = newVal
      }
    },
    hasChannel: {
      get: function () {
        if ((store.state.app.userInfo && store.state.app.userInfo.channel && store.state.app.userInfo.channel.name) || this.freshChannel) {
          return true
        } else {
          return false
        }
      },
      set: function () {

      }
    },
    channelId () {
      if (this.publishId) {
        return this.publishId
      }
      if (store.state.app.userInfo && store.state.app.userInfo.channel && store.state.app.userInfo.channel.name) {
        return store.state.app.userInfo.channel.id
      } else {
        return '0cc95269eb80460faca4a4b56fd49ce9'
      }
    },
    userInfo () {
      return store.state.app.userInfo
    },
    podcastInfo () {
      if (store.state.editor.podcastInfo) {
        return store.state.editor.podcastInfo
      }
      return {}
    }
  },
  watch: {
    currentState () {
      var vm = this
      if (vm.currentState !== 0) {
        vm.beginCountdown()
      }
    }
  },
  mounted () {
    let vm = this
    vm.ownerId = vm.$route.params.userId
    vm.podId = vm.$route.params.podId
  },
  methods: {
    sampleLink () {
      window.open(CONSTANTS.SAMPLE_PUBLISH_LINK)
    },
    channelLink () {
      window.open(CONSTANTS.PUBLISH_EPSIODE_BASE + this.channelId)
    },
    sampleEpisode () {
      window.open(CONSTANTS.SAMPLE_PUBLISH_EPSIODE)
    },
    publish () {
      console.log('call the publishing api')
      let vm = this
      axios({
        method: 'get',
        url: CONSTANTS.SPEXT_PUBLISH,
        params: {
          userid: vm.ownerId,
          media_id: vm.podId,
          channel_id: this.channelId,
          title: this.title
        }
      }).then(function (response) {
        console.log('publishing api says ->', response)
        vm.currentState = 202 // state of correct execution
        setTimeout(function () {
          vm.clear()
        }, 5000)
      }).catch(function (err) {
        vm.currentState = 504 // something's went wrong
        console.log('err', err)
        setTimeout(function () {
          vm.clear()
        }, 5000)
      })
    },
    clear () {
      let vm = this
      store.dispatch('modal/closePublishModal')
      setTimeout(function () {
        this.currentState = 0
        this.publishTitle = ''
        this.uploadStatus = ''
      }, 5000)
    },
    openIntercom () {
      window.Intercom('showNewMessage', 'Error in Publishing')
    },
    createChannel () {
      var vm = this
      if (!vm.channelName) {
        vm.currentState = 505
        return
      }
      // upload image
      let chid = uuidv1().replace(/^(.{8})-(.{4})-(.{4})/, '$3-$2-$1')
      // upload into storage at location - /published/<userId>/channels/<channelid>/profileImage
      if (store.state.app.localChannelImage) {
        let uploadTask = uploadImagePublish(store.state.app.user.uid, chid, store.state.app.localChannelImage)
        uploadTask.on('state_changed', function (snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          vm.percentage = progress.toFixed(2)
          vm.uploadStatus = 'inprogress'
        }, function (err) {
          console.log(err)
          vm.currentState = 502
        }, function () {
          const path = uploadTask.snapshot.ref.fullPath
          const url = getURLfromPath(path)
          getURLfromPath(path)
            .then(function (url) {
              console.log('image uploaded into storage', url)
              vm.uploadStatus = 'complete'
              // get the channel name and image and make entry in db
              var data = {
                'name': vm.channelName,
                'userid': vm.ownerId,
                'channel_id': chid,
                'desc': vm.channelDesc,
                'image': url
              }
              // call api to insert channel
              // insert channel name, id, in userInfo
              // insert name, id, image, description into published db
              axios({
                method: 'POST',
                url: CONSTANTS.SPEXT_CHANNEL_PUBLISH,
                data: data
              }).then(function (response) {
                console.log('channel api says ->', response)
                vm.currentState = 201 // channel created
                vm.freshChannel = true
                vm.publishId = chid
              }).catch(function (err) {
                vm.currentState = 501 // channel creation failed
                console.log('err', err)
              })
            })
        })
      } else {
        vm.currentState = 502
      }

      // firebase.database().ref(`${vm.ownerId}/podcast-info/${vm.podId}/channel`).set(val).then(function () {
      //   vm.currentState = 201
      //   vm.freshChannel = true
      // }).catch(function (err) {
      //   console.log(err)
      //   vm.currentState = 501
      // })
    },
    beginCountdown () {
      var vm = this
      if (vm.countdownId !== '') {
        clearInterval(vm.countdownId)
      }
      vm.countdown = 10
      vm.countdownId = setInterval(reduceTime, 1000)

      function reduceTime () {
        vm.countdown -= 1
        if (vm.countdown === 0) {
          clearInterval(vm.countdownId)
          vm.resetCurrentState()
        }
      }
    },
    resetCurrentState () {
      let vm = this
      if (vm.currentState === 201) {
        vm.currentState = 0
        return
      }
      vm.currentState = 0
      vm.freshChannel = ''
    },
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    }
  }
}
</script>

<style lang="less" scoped>
.whiteText {
    color: white !important;
}

.tealText {
    color: #5A29A3;
}

.ui.teal.button {
    background-color: #5A29A3 !important;
}

.ui.teal.basic.button {
    background-color: transparent !important;
    box-shadow: 0 0 0 1px #5A29A3 inset !important;
    color: white !important;
}

.ui.inverted.basic.segment {
    margin-top: 0px;
}

.transparent {
    background: transparent;
    color: white;
}

.myButton {
    cursor: pointer;
}

.btn-publish {
    cursor: pointer;
    padding: 8px 16px;
    border: none;
    color: #ffffff;
    background: #5A29A3;
    border-radius: 5px;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-right: 9px;
    margin-left: 9px;
    margin-top: 10px;
}

.btn-publish:hover {
    background: #5A29A3;
}

.borderBottom {
    border-bottom: #5A29A3;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

a:link {
    color: #5A29A3;
    text-decoration: underline;
}

/* mouse over link */
a:hover {
    color: white;
}

.upload-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .upload-info {
      color: #324851;
      font-size: 18px;
      span {
        padding: 0 8px;
      }
    }
    .progress-bar {
      padding: 16px 0;
      .progress-outer {
        width: 265px;
        background-color: #f3f3f3;
        height: 10px;
        border-radius: 10px;
      }
      .progress-inner {
        width: 1%;
        background-color: #5A29A3;
        height: 10px;
        border-radius: 10px;
        transition: 0.2s ease;
      }
    }
  }
  .upload-complete-container {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-check-circle {
      i {
        color: #5A29A3;
        font-size: 20px;
      }
    }
    .file-title {
      font-size: 18px;
    }
    span {
      padding: 0 8px;
    }
  }
</style>
